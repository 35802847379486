exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-groups-tsx": () => import("./../../../src/pages/groups/[...].tsx" /* webpackChunkName: "component---src-pages-groups-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-problem-solutions-tsx": () => import("./../../../src/pages/problem-solutions.tsx" /* webpackChunkName: "component---src-pages-problem-solutions-tsx" */),
  "component---src-pages-problems-tsx": () => import("./../../../src/pages/problems.tsx" /* webpackChunkName: "component---src-pages-problems-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-templates-module-template-tsx": () => import("./../../../src/templates/moduleTemplate.tsx" /* webpackChunkName: "component---src-templates-module-template-tsx" */),
  "component---src-templates-syllabus-template-tsx": () => import("./../../../src/templates/syllabusTemplate.tsx" /* webpackChunkName: "component---src-templates-syllabus-template-tsx" */)
}

