import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import {
  AuthCredential,
  getAuth,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  signInWithCredential,
  signInWithPopup,
} from 'firebase/auth';
import React, { Fragment, useContext } from 'react';
import UserDataContext from '../context/UserDataContext/UserDataContext';
import { useFirebaseApp } from '../hooks/useFirebase';
import { LoadingSpinner } from './elements/LoadingSpinner';

export interface SignInModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SignInModal: React.FC<SignInModalProps> = ({
  isOpen,
  onClose,
}) => {
  const firebaseApp = useFirebaseApp();
  const { triggerUserDataContextRerender } = useContext(UserDataContext);
  const [isSigningIn, setIsSigningIn] = React.useState(false);
  const [isLinking, setIsLinking] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [credential, setCredential] = React.useState<AuthCredential>(null);

  const diffCredentialMessage = 'auth/account-exists-with-different-credential';
  const handleSignInWithGoogle = () => {
    setIsSigningIn(true);
    setError(null);
    signInWithPopup(getAuth(firebaseApp), new GoogleAuthProvider())
      .then(() => {
        setIsSigningIn(false);
        onClose();
      })
      .catch(e => {
        if (e.code === diffCredentialMessage) {
          setIsLinking(true);
          const credential = GoogleAuthProvider.credentialFromError(e);
          setEmail(e.customData.email);
          setCredential(credential);
        } else {
          setError(e);
        }
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };
  const handleSignInWithGithub = () => {
    setIsSigningIn(true);
    setError(null);
    signInWithPopup(getAuth(firebaseApp), new GithubAuthProvider())
      .then(() => {
        onClose();
      })
      .catch(e => {
        if (e.code === diffCredentialMessage) {
          setIsLinking(true);
          const credential = GithubAuthProvider.credentialFromError(e);
          setEmail(e.customData.email);
          setCredential(credential);
        } else {
          setError(e);
        }
      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  // links account from credential with the account from other provider (either Google or Github)
  const handleLinkAccounts = async () => {
    try {
      let otherProvider: GoogleAuthProvider | GithubAuthProvider;
      if (credential.signInMethod === 'github.com') {
        otherProvider = new GoogleAuthProvider();
      } else if (credential.signInMethod === 'google.com') {
        otherProvider = new GithubAuthProvider();
      } else {
        throw new Error('Unsupported sign in method');
      }
      otherProvider.setCustomParameters({ login_hint: email });
      await signInWithPopup(getAuth(firebaseApp), otherProvider);
      await linkWithCredential(getAuth(firebaseApp).currentUser, credential);
      await signInWithCredential(getAuth(firebaseApp), credential);

      // At this point, getAuth(firebaseApp).currentUser is updated with the latest credentials
      // However, onAuthStateChanged() is not called, so we need to manually notify React
      // that firebaseUser has changed and we should rerender things that depend on it.
      // In particular, without this, the "Linked Providers" section under "Sign In Methods"
      // in Settings will not update until the page is reloaded.
      triggerUserDataContextRerender();

      onClose();
    } catch (e) {
      setError(e);
      console.log(e);
    } finally {
      setIsLinking(false);
    }
  };

  React.useEffect(() => {
    if (isOpen) setError(null);
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        // navbar has z-20
        className="fixed z-30 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-dark-surface rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200"
                >
                  Sign In
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Signing in with your google account.  
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 space-y-4">
                <div className="space-y-4 sm:space-y-0 sm:flex sm:items-center sm:space-x-4">
                  <button
                    type="button"
                    className="btn pl-3"
                    onClick={handleSignInWithGoogle}
                    disabled={!firebaseApp || isSigningIn}
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 48 48"
                      className="abcRioButtonSvg"
                    >
                      <g>
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        />
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        />
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        />
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        />
                        <path fill="none" d="M0 0h48v48H0z" />
                      </g>
                    </svg>
                    <span className="ml-3">Sign In With Google</span>
                  </button>
                  {isSigningIn && <LoadingSpinner />}
                </div>
                {error && (
                  <div>
                    <p className="text-red-700 dark:text-red-300">
                      Error: {error.code}
                    </p>
                  </div>
                )}
                {isLinking && (
                  <div>
                    <p className="text-red-700 dark:text-red-300">
                      An account with this email already exists, please sign in
                      with Google to link the two accounts:
                    </p>
                    <button
                      type="button"
                      className="btn pl-3 mt-3"
                      onClick={handleLinkAccounts}
                      // disabled={!firebaseApp || isSigningIn}
                    >
                      Link Accounts
                    </button>
                  </div>
                )}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white dark:bg-dark-surface rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-dark-surface focus:ring-blue-500"
                  onClick={() => {
                    setIsLinking(false);
                    onClose();
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
