export default {
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=807': 'usaco-807',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=591': 'usaco-591',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1059': 'usaco-1059',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=987': 'usaco-987',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=939': 'usaco-939',
  'https://open.kattis.com/problems/basketballoneonone':
    'kattis-BasketballOneOnOne',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=674': 'usaco-674',
  'https://codeforces.com/contest/546/problem/D': 'cf-546D',
  'https://cses.fi/problemset/task/1068': 'cses-1068',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=567': 'usaco-567',
  'https://codeforces.com/gym/102951/problem/A': 'cfgym-102951A',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=615': 'usaco-615',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=639': 'usaco-639',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1060': 'usaco-1060',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=963': 'usaco-963',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=736': 'usaco-736',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1011': 'usaco-1011',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=784': 'usaco-784',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=712': 'usaco-712',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=893': 'usaco-893',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=739': 'usaco-739',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=617': 'usaco-617',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=569': 'usaco-569',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1037': 'usaco-1037',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=640': 'usaco-640',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=619': 'usaco-619',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=915': 'usaco-915',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=892': 'usaco-892',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=809': 'usaco-809',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=737': 'usaco-737',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=808': 'usaco-808',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1088': 'usaco-1088',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=894': 'usaco-894',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=916': 'usaco-916',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=940': 'usaco-940',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=965': 'usaco-965',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1013': 'usaco-1013',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=941': 'usaco-941',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=833': 'usaco-833',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1012': 'usaco-1012',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=689': 'usaco-689',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1084': 'usaco-1084',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=785': 'usaco-785',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=989': 'usaco-989',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=810': 'usaco-810',
  'https://cses.fi/problemset/task/1621': 'cses-1621',
  'https://judge.yosupo.jp/problem/associative_array': 'ys-AssociativeArray',
  'https://cses.fi/problemset/task/1640': 'cses-1640',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=964': 'usaco-964',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=831': 'usaco-831',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1107': 'usaco-1107',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=687': 'usaco-687',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=667': 'usaco-667',
  'https://codeforces.com/contest/831/problem/C': 'cf-831C',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=759': 'usaco-759',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=663': 'usaco-663',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=783': 'usaco-783',
  'https://codeforces.com/contest/1216/problem/C': 'cf-1216C',
  'https://www.hackerrank.com/challenges/ctci-bubble-sort/problem':
    'hr-BubbleSort',
  'https://codeforces.com/contest/863/problem/B': 'cf-863B',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=713': 'usaco-713',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=592': 'usaco-592',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=891': 'usaco-891',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=855': 'usaco-855',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=665': 'usaco-665',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=568': 'usaco-568',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=735': 'usaco-735',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=760': 'usaco-760',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=917': 'usaco-917',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=616': 'usaco-616',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=664': 'usaco-664',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=593': 'usaco-593',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=526': 'usaco-526',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=761': 'usaco-761',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1061': 'usaco-1061',
  'https://codeforces.com/contest/1201/problem/C': 'cf-1201C',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=690': 'usaco-690',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=858': 'usaco-858',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=594': 'usaco-594',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1038': 'usaco-1038',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=597': 'usaco-597',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=991': 'usaco-991',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=624': 'usaco-624',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=666': 'usaco-666',
  'https://codeforces.com/contest/702/problem/C': 'cf-702C',
  'https://cses.fi/problemset/task/1620': 'cses-1620',
  'https://cses.fi/problemset/task/1085': 'cses-1085',
  'https://cses.fi/problemset/task/2422': 'cses-2422',
  'https://codeforces.com/problemset/problem/1117/C': 'cf-1117C',
  'https://codeforces.com/contest/782/problem/B': 'cf-782B',
  'https://codeforces.com/contest/847/problem/B': 'cf-847B',
  'https://codeforces.com/contest/847/problem/E': 'cf-847E',
  'https://codeforces.com/problemset/problem/818/F': 'cf-818F',
  'https://oj.uz/problem/view/BOI12_mobile': 'baltic-12-mobile',
  'https://cses.fi/problemset/task/1666': 'cses-1666',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=644': 'usaco-644',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=668': 'usaco-668',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=944': 'usaco-944',
  'https://open.kattis.com/problems/birthday': 'kattis-birthday',
  'https://cses.fi/problemset/task/1682': 'cses-1682',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=669': 'usaco-669',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=992': 'usaco-992',
  'https://open.kattis.com/problems/laneswitching': 'kattis-LaneSwitching',
  'https://cses.fi/problemset/task/1668': 'cses-1668',
  'https://codeforces.com/contest/862/problem/B': 'cf-862B',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=920': 'usaco-920',
  'https://codeforces.com/contest/1387/problem/A': 'baltic-20-graph',
  'https://dmoj.ca/problem/apio11p1': 'apio-11-TableColoring',
  'https://cses.fi/problemset/task/1660': 'cses-1660',
  'https://codeforces.com/contest/279/problem/B': 'cf-279B',
  'https://cses.fi/problemset/task/1641': 'cses-1641',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=738': 'usaco-738',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=643': 'usaco-643',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=918': 'usaco-918',
  'https://codeforces.com/problemset/problem/814/C': 'cf-814C',
  'https://oj.uz/problem/view/CEOI10_tower': 'ceoi-10-AHugeTower',
  'https://cses.fi/problemset/task/1091': 'cses-1091',
  'https://cses.fi/problemset/task/1163': 'cses-1163',
  'https://cses.fi/problemset/task/1164': 'cses-1164',
  'https://cses.fi/problemset/task/1188': 'cses-1188',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=763': 'usaco-763',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=859': 'usaco-859',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=813': 'usaco-813',
  'https://codeforces.com/contest/862/problem/E': 'cf-862E',
  'https://codeforces.com/contest/878/problem/C': 'cf-878C',
  'https://saco-evaluator.org.za/cms/sapo2018z': 'sapo-18-stadium',
  'https://cses.fi/problemset/task/1192': 'cses-1192',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=895': 'usaco-895',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=570': 'usaco-570',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=596': 'usaco-596',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=620': 'usaco-620',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=740': 'usaco-740',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=716': 'usaco-716',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=860': 'usaco-860',
  'https://dmoj.ca/problem/ccc20s2': 'cco-EscapeRoom',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1110': 'usaco-1110',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=811': 'usaco-811',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=836': 'usaco-836',
  'https://codeforces.com/gym/102951/problem/B': 'cfgym-102951B',
  'https://cses.fi/problemset/task/1629': 'cses-1629',
  'https://cses.fi/problemset/task/1074': 'cses-1074',
  'https://cses.fi/problemset/task/1084': 'cses-1084',
  'https://cses.fi/problemset/task/1090': 'cses-1090',
  'https://cses.fi/problemset/task/1630': 'cses-1630',
  'https://cses.fi/problemset/task/1073': 'cses-1073',
  'https://cses.fi/problemset/task/1632': 'cses-1632',
  'https://cses.fi/problemset/task/1161': 'cses-1161',
  'https://codeforces.com/contest/762/problem/B': 'cf-762B',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=835': 'usaco-835',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=571': 'usaco-571',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=573': 'usaco-573',
  'https://csacademy.com/contest/archive/task/sure-bet': 'csa-SureBet',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=714': 'usaco-714',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=990': 'usaco-990',
  'https://codeforces.com/contest/1020/problem/B': 'cf-1020B',
  'https://codeforces.com/contest/1137/problem/D': 'cf-1137D',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=764': 'usaco-764',
  'https://cses.fi/problemset/task/1751': 'cses-1751',
  'https://cses.fi/problemset/task/1674': 'cses-1674',
  'https://codeforces.com/contest/755/problem/C': 'cf-755C',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=788': 'usaco-788',
  'https://codeforces.com/contest/839/problem/C': 'cf-839C',
  'https://oj.uz/problem/view/IOI10_traffic': 'ioi-10-traffic',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=968': 'usaco-968',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1062': 'usaco-1062',
  'https://cses.fi/problemset/task/1131': 'cses-1131',
  'https://cses.fi/problemset/task/1132': 'cses-1132',
  'https://cses.fi/problemset/task/1133': 'cses-1133',
  'https://szkopul.edu.pl/problemset/problem/CUjJDGGSEZmO7HvdZU4FKrL6/site/?key=statement':
    'poi-17-sabotage',
  'https://dmoj.ca/problem/ccc16s3': 'ccc-PhenomenalReviews',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1016': 'usaco-1016',
  'https://szkopul.edu.pl/problemset/problem/gDw3iFkeVm7ZA3j_16-XR7jI/site/?key=statement':
    'poi-14-hotels',
  'https://cses.fi/problemset/task/2179': 'cses-2179',
  'https://codeforces.com/contest/860/problem/D': 'cf-860D',
  'https://judge.yosupo.jp/problem/static_range_sum': 'ys-StaticRangeSum',
  'https://leetcode.com/problems/find-pivot-index': 'lc-FindPivotIndex',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=572': 'usaco-572',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=595': 'usaco-595',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=691': 'usaco-691',
  'https://cses.fi/problemset/task/1661': 'cses-1661',
  'https://cses.fi/problemset/task/1662': 'cses-1662',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=715': 'usaco-715',
  'https://cses.fi/problemset/task/1650': 'cses-1650',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=104': 'usaco-104',
  'https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ff43/0000000000337b4d':
    'ks-CandiesTestSet1',
  'https://atcoder.jp/contests/abc164/tasks/abc164_d': 'ac-MultipleOf2019',
  'https://atcoder.jp/contests/dp/tasks/dp_v': 'ac-subtree',
  'https://www.acmicpc.net/problem/14875': 'balkan-17-CityAttractions',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=814': 'usaco-814',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=793': 'usaco-793',
  'https://dmoj.ca/problem/apio10p2': 'apio-10-patrol',
  'https://oj.uz/problem/view/IZhO17_road': 'izho-17-HardRoute',
  'https://oj.uz/problem/view/APIO14_beads': 'apio-14-beads',
  'https://codeforces.com/contest/1402/problem/C': 'ceoi-20-StarTrek',
  'https://cses.fi/problemset/task/1643': 'cses-1643',
  'https://cses.fi/problemset/task/1652': 'cses-1652',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=919': 'usaco-919',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1063': 'usaco-1063',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=923': 'usaco-923',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=744': 'usaco-744',
  'https://cses.fi/problemset/task/1667': 'cses-1667',
  'https://oj.uz/problem/view/BOI13_tracks': 'baltic-13-TracksInTheSnow',
  'https://cses.fi/problemset/task/1193': 'cses-1193',
  'https://cses.fi/problemset/task/1194': 'cses-1194',
  'https://cses.fi/problemset/task/1707': 'cses-1707',
  'https://csacademy.com/contest/archive/task/bfs-dfs': 'csa-bfsdfs',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=491': 'usaco-491',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=671': 'usaco-671',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=790': 'usaco-790',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=282': 'usaco-282',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1090': 'usaco-1090',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=695': 'usaco-695',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=575': 'usaco-575',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1065': 'usaco-1065',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=765': 'usaco-765',
  'https://judge.yosupo.jp/problem/unionfind': 'ys-UnionFind',
  'https://cses.fi/problemset/task/1676': 'cses-1676',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=646': 'usaco-646',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=789': 'usaco-789',
  'https://csacademy.com/contest/archive/task/mountain-time':
    'csa-MountainTime',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=384': 'usaco-384',
  'https://oj.uz/problem/view/BOI16_park': 'baltic-16-park',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1042': 'usaco-1042',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=950': 'usaco-950',
  'https://cses.fi/problemset/task/1130': 'cses-1130',
  'https://atcoder.jp/contests/dp/tasks/dp_p': 'ac-IndependentSet',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=766': 'usaco-766',
  'https://codeforces.com/contest/1387/problem/B1': 'baltic-20-VillageMinimum',
  'https://szkopul.edu.pl/problemset/problem/1QaUWE_ePAmitZjgAszOVD1U/site/?key=statement':
    'poi-parade',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1019': 'usaco-1019',
  'https://szkopul.edu.pl/problemset/problem/r6tMTfvQFPAEfQioYMCQndQe/site/?key=statement':
    'poi-04-spies',
  'https://szkopul.edu.pl/problemset/problem/w3YAoAT3ej27YeiaNWjK57_G/site/?key=statement':
    'poi-08-mafia',
  'https://oj.uz/problem/view/COI16_torrent': 'coi-16-torrent',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1020': 'usaco-1020',
  'https://codeforces.com/contest/1394/problem/D': 'cf-1394D',
  'https://oj.uz/problem/view/IOI07_training': 'ioi-07-training',
  'https://cses.fi/problemset/task/1752': 'cses-1752',
  'https://oj.uz/problem/view/BOI16_swap': 'baltic-16-swap',
  'https://cses.fi/problemset/task/1713': 'cses-1713',
  'https://atcoder.jp/contests/abc169/tasks/abc169_d': 'ac-DivGame',
  'https://cses.fi/problemset/task/1081': 'cses-1081',
  'https://codeforces.com/contest/1349/problem/A': 'cf-1349A',
  'https://cses.fi/problemset/task/1082': 'cses-1082',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=994': 'usaco-994',
  'https://cses.fi/problemset/task/1642': 'cses-1642',
  'https://cses.fi/problemset/task/1095': 'cses-1095',
  'https://cses.fi/problemset/task/1712': 'cses-1712',
  'https://codeforces.com/contest/1279/problem/D': 'cf-1279D',
  'https://cses.fi/problemset/task/2182': 'cses-2182',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=694': 'usaco-694',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=993': 'usaco-993',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=863': 'usaco-863',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=945': 'usaco-945',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=622': 'usaco-622',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=815': 'usaco-815',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=971': 'usaco-971',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=673': 'usaco-673',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=791': 'usaco-791',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1066': 'usaco-1066',
  'https://cses.fi/problemset/task/1633': 'cses-1633',
  'https://cses.fi/problemset/task/1634': 'cses-1634',
  'https://cses.fi/problemset/task/1635': 'cses-1635',
  'https://cses.fi/problemset/task/1636': 'cses-1636',
  'https://cses.fi/problemset/task/1158': 'cses-1158',
  'https://cses.fi/problemset/task/1745': 'cses-1745',
  'https://cses.fi/problemset/task/1093': 'cses-1093',
  'https://cses.fi/problemset/task/1665': 'cses-1665',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=574': 'usaco-574',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=839': 'usaco-839',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=925': 'usaco-925',
  'http://codeforces.com/contest/837/problem/D': 'cf-837D',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=897': 'usaco-897',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1043': 'usaco-1043',
  'https://szkopul.edu.pl/problemset/problem/lGqKS9urITMjTXhpdaHqyoEL/site/?key=statement':
    'poi-04-maximal',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1045': 'usaco-1045',
  'https://cses.fi/problemset/task/1145': 'cses-1145',
  'https://cses.fi/105/list/': 'baltic-10-pcb',
  'https://codeforces.com/gym/102951/problem/C': 'cfgym-102951C',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=496': 'usaco-496',
  'https://oj.uz/problem/view/LMIO19_triusis': 'lmio-19-triusis',
  'https://cses.fi/107/list/': 'baltic-09-beetle',
  'https://oj.uz/problem/view/CEOI18_glo': 'ceoi-18-GlobalWarming',
  'https://joisc2016.contest.atcoder.jp/tasks/joisc2016_a': 'joi-16-matryoshka',
  'https://cses.fi/problemset/task/1675': 'cses-1675',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=531': 'usaco-531',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=623': 'usaco-623',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=946': 'usaco-946',
  'https://www.hackerrank.com/contests/w31/challenges/spanning-tree-fraction/problem':
    'hr-SpanningTreeFraction',
  'https://joisc2015.contest.atcoder.jp/tasks/joisc2015_k':
    'joi-15-inheritance',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=625': 'usaco-625',
  'https://oj.uz/problem/view/COCI17_sirni': 'coci-17-sirni',
  'https://oj.uz/problem/view/APIO13_toll': 'apio-13-toll',
  'http://oj.uz/problem/view/balkan11_timeismoney': 'balkan-11-timeismoney',
  'https://cses.fi/problemset/task/1638': 'cses-1638',
  'https://leetcode.com/problems/longest-common-subsequence/':
    'lc-LongestCommonSubsequence',
  'https://cses.fi/problemset/task/1746': 'cses-1746',
  'https://cses.fi/problemset/task/1639': 'cses-1639',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=670': 'usaco-670',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=598': 'usaco-598',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=718': 'usaco-718',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=553': 'usaco-553',
  'https://cses.fi/problemset/task/1671': 'cses-1671',
  'https://cses.fi/problemset/task/1195': 'cses-1195',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=969': 'usaco-969',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=717': 'usaco-717',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=861': 'usaco-861',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=899': 'usaco-899',
  'https://cses.fi/problemset/task/1202': 'cses-1202',
  'https://open.kattis.com/problems/robotturtles': 'kattis-robotturtles',
  'https://cses.fi/problemset/task/1196': 'cses-1196',
  'https://oj.uz/problem/view/IOI11_crocodile': 'ioi-11-crocodile',
  'https://oj.uz/problem/view/JOI18_commuter_pass': 'joi-18-CommuterPass',
  'https://oj.uz/problem/view/JOI21_ho_t4': 'joi-21-robot',
  'https://dmoj.ca/problem/apio11p2': 'apio-11-FindThePath',
  'https://www.acmicpc.net/problem/5250': 'balkan-12-ShortestPaths',
  'https://oj.uz/problem/view/Balkan15_CIRCUS': 'balkan-15-circus',
  'https://cses.fi/problemset/task/1672': 'cses-1672',
  'https://cses.fi/problemset/task/1648': 'cses-1648',
  'https://cses.fi/problemset/task/1649': 'cses-1649',
  'https://www.spoj.com/problems/INVCNT': 'spoj-InversionCounting',
  'https://cses.fi/problemset/task/1651': 'cses-1651',
  'https://open.kattis.com/problems/megainversions': 'kattis-megainversions',
  'https://cses.fi/problemset/task/1749': 'cses-1749',
  'https://cses.fi/problemset/task/1144': 'cses-1144',
  'https://cses.fi/problemset/task/1748': 'cses-1748',
  'https://cses.fi/problemset/task/1734': 'cses-1734',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1041': 'usaco-1041',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=693': 'usaco-693',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=719': 'usaco-719',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=898': 'usaco-898',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=720': 'usaco-720',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=532': 'usaco-532',
  'https://leetcode.com/problems/sliding-window-maximum':
    'lc-SlidingWindowMaximum',
  'https://cses.fi/problemset/task/1644': 'cses-1644',
  'https://cses.fi/problemset/task/1076': 'cses-1076',
  'https://cses.fi/problemset/task/1077': 'cses-1077',
  'https://cses.fi/problemset/task/1141': 'cses-1141',
  'https://codeforces.com/contest/616/problem/D': 'cf-616D',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=767': 'usaco-767',
  'https://oj.uz/problem/view/APIO15_bridge': 'apio-15-PalembangBridges',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=600': 'usaco-600',
  'https://dmoj.ca/problem/apio09p1': 'apio-09-DiggingForOil',
  'https://contest.yandex.com/ioi/contest/566/problems/A/': 'ioi-05-garden',
  'https://contest.yandex.com/ioi/contest/562/problems/B/': 'ioi-06-pyramid',
  'https://judge.yosupo.jp/problem/queue_operate_all_composite':
    'ys-QueueComposite',
  'https://oj.uz/problem/view/BOI15_hac': 'baltic-15-hacker',
  'https://cses.fi/problemset/task/1645': 'cses-1645',
  'https://leetcode.com/problems/largest-rectangle-in-histogram':
    'lc-MaxHistogramArea',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=743': 'usaco-743',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=922': 'usaco-922',
  'https://cses.fi/problemset/task/1147': 'cses-1147',
  'https://codeforces.com/contest/1402/problem/A': 'ceoi-20-FancyFence',
  'https://cses.fi/problemset/task/2416': 'cses-2416',
  'https://contest.yandex.com/ioi/contest/560/problems/D/': 'ioi-04-empodia',
  'https://infoarena.ro/problema/ssdj': 'infoarena-ssdj',
  'https://cses.fi/problemset/task/1148': 'cses-1148',
  'https://oj.uz/problem/view/COI15_zarulje': 'coi-15-arulje',
  'https://dmoj.ca/problem/ccc20s3': 'ccc-SearchingForStrings',
  'https://cses.fi/165/list/': 'ceoi-17-PalindromicPartitions',
  'https://codeforces.com/contest/835/problem/D': 'cf-835D',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=741': 'usaco-741',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=599': 'usaco-599',
  'https://csacademy.com/contest/rmi-2017-day-1/task/hangman2/':
    'rmi-17-Hangman2',
  'https://oj.uz/problem/view/COCI17_osmosmjerka': 'coci-17-osmosmjerka',
  'https://evaluator.hsin.hr/tasks/HONI202135sateliti/': 'coci-21-sateliti',
  'https://codeforces.com/contest/822/problem/E': 'cf-822E',
  'https://boi18-day2-open.kattis.com/problems/genetics2': 'baltic-18-genetics',
  'https://oj.uz/problem/view/COCI16_zamjene': 'coci-16-zamjene',
  'https://oj.uz/problem/view/COI16_palinilap': 'coi-16-palinilap',
  'https://cses.fi/problemset/task/1679': 'cses-1679',
  'https://cses.fi/problemset/task/1678': 'cses-1678',
  'https://cses.fi/problemset/task/1680': 'cses-1680',
  'https://cses.fi/problemset/task/1681': 'cses-1681',
  'https://open.kattis.com/problems/quantumsuperposition':
    'kattis-quantumsuperposition',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1017': 'usaco-1017',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=838': 'usaco-838',
  'https://cses.fi/problemset/task/1757': 'cses-1757',
  'https://cses.fi/problemset/task/1739': 'cses-1739',
  'https://dmoj.ca/problem/bfs17p6': 'dmoj-CrowdedCities',
  'https://oj.uz/problem/view/IOI07_pairs': 'ioi-07-pairs',
  'https://dmoj.ca/problem/dmopc19c7p5': 'dmoj-SoriyasProgrammingProject',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=722': 'usaco-722',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=601': 'usaco-601',
  'https://oj.uz/problem/view/APIO19_street_lamps': 'apio-19-StreetLamps',
  'https://szkopul.edu.pl/problemset/problem/OQjANSOOD_-c38gh8p6g3Gxp/site/?key=statement':
    'poi-06-Tetris3D',
  'https://oj.uz/problem/view/IOI13_game': 'ioi-13-game',
  'https://oj.uz/problem/view/JOI17_golf': 'joi-17-golf',
  'https://codeforces.com/contest/1083/problem/E': 'cf-1083E',
  'https://dmoj.ca/problem/apio10p1': 'apio-10-commando',
  'https://szkopul.edu.pl/problemset/problem/ovRIpLFK3IhyFPjnVXeZtGxH/site/?key=statement':
    'ceoi-04-TwoSawmills',
  'https://oj.uz/problem/view/CEOI09_harbingers': 'ceoi-09-harbingers',
  'https://dmoj.ca/problem/ioi02p4': 'ioi-02-BatchScheduling',
  'https://oj.uz/problem/view/APIO14_sequence': 'apio-14-SplitTheSequence',
  'https://szkopul.edu.pl/problemset/problem/iYVwsAcHHCRZzAtQh0QFKbsu/site/':
    'poi-11-LightningConductor',
  'https://szkopul.edu.pl/problemset/problem/HH7LQVRVHom1g8YRe9423d1P/site/':
    'poi-06-frogs',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=626': 'usaco-626',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=998': 'usaco-998',
  'https://oj.uz/problem/view/JOI17_coach': 'joi-17-LongDistanceCoach',
  'https://cses.fi/problemset/task/1706': 'cses-1706',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=862': 'usaco-862',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=672': 'usaco-672',
  'https://csacademy.com/contest/archive/task/substring-restrictions':
    'csa-SubstringRestrictions',
  'https://tlx.toki.id/contests/troc-15-div-1/problems/E':
    'toki-TzaphNumberLine',
  'https://oj.uz/problem/view/BOI19_nautilus': 'baltic-19-nautilus',
  'https://cses.fi/106/list/': 'baltic-10-candies',
  'https://oj.uz/problem/view/COCI15_uzastopni': 'coci-15-uzastopni',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1021': 'usaco-1021',
  'https://oj.uz/problem/view/IZhO17_bootfall': 'izho-17-bootfall',
  'https://oj.uz/problem/view/BOI15_tug': 'baltic-15-TugOfWar',
  'https://codeforces.com/contest/1444/problem/D': 'cf-1444D',
  'https://open.kattis.com/problems/convexhull': 'kattis-convexhull',
  'https://open.kattis.com/problems/roberthood': 'kattis-roberthood',
  'https://codeforces.com/problemset/problem/1299/C': 'cf-1299C',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=864': 'usaco-864',
  'https://codeforces.com/problemset/problem/1195/F': 'cf-1195F',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=382': 'usaco-382',
  'https://open.kattis.com/problems/fenceortho': 'kattis-fenceortho',
  'https://atcoder.jp/contests/agc044/tasks/agc044_e': 'ac-AGCERandomPawn',
  'https://cses.fi/problemset/task/1647': 'cses-1647',
  'https://oj.uz/problem/view/JOI14_secret': 'joi-14-secret',
  'https://www.codechef.com/problems/SEGPROD': 'cc-ProductOnSegment',
  'https://oj.uz/problem/view/BOI17_toll': 'baltic-17-toll',
  'https://dmoj.ca/problem/dmopc19c7p4': 'dmoj-ContinuedFractions',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=997': 'usaco-997',
  'https://cses.fi/problemset/task/1690': 'cses-1690',
  'https://atcoder.jp/contests/dp/tasks/dp_o?lang=en': 'ac-matching',
  'https://codeforces.com/contest/1316/problem/E': 'cf-1316E',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=494': 'usaco-494',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1089': 'usaco-1089',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=515': 'usaco-515',
  'https://cses.fi/problemset/task/1653': 'cses-1653',
  'https://oj.uz/problem/view/IZhO14_bank': 'izho-14-bank',
  'https://judge.yosupo.jp/problem/maximum_independent_set': 'ys-MaxIndepSet',
  'https://open.kattis.com/problems/catandmice': 'kattis-catandmice',
  'https://oj.uz/problem/view/IZhO17_subsequence':
    'izho-17-LongestBeautifulSequence',
  'https://oj.uz/problem/view/COCI16_burza': 'coci-16-burza',
  'https://oj.uz/problem/view/CEOI19_amusementpark': 'ceoi-19-AmusementPark',
  'https://codeforces.com/contest/1043/problem/F': 'cf-1043F',
  'https://codeforces.com/contest/1103/problem/D': 'cf-1103D',
  'https://codeforces.com/contest/1148/problem/G': 'cf-1148G',
  'https://codeforces.com/contest/1292/problem/F': 'cf-1292F',
  'https://judge.yosupo.jp/problem/sort_points_by_argument':
    'ys-SortPointsByArgument',
  'https://open.kattis.com/problems/segmentintersection':
    'kattis-segmentintersection',
  'https://open.kattis.com/problems/segmentdistance': 'kattis-segmentdistance',
  'https://open.kattis.com/problems/pointinpolygon': 'kattis-pointinpolygon',
  'https://open.kattis.com/problems/polygonarea': 'kattis-polygonarea',
  'https://codeforces.com/contest/598/problem/F': 'cf-598F',
  'https://open.kattis.com/problems/maxcolinear': 'kattis-maxcolinear',
  'https://open.kattis.com/problems/birthdaycake': 'kattis-birthdaycake',
  'https://saco-evaluator.org.za/cms/sapo2015z/tasks/jazz/description':
    'sapo-15-SpaceJazz',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=647': 'usaco-647',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1114': 'usaco-1114',
  'https://cses.fi/problemset/task/1080': 'cses-1080',
  'https://codeforces.com/problemset/problem/607/B': 'cf-607B',
  'https://saco-evaluator.org.za/cms/sapo2014z/tasks/genghis/description':
    'sapo-14-TheStablesOfGenghisKhan',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=972': 'usaco-972',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=698': 'usaco-698',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=602': 'usaco-602',
  'https://oj.uz/problem/view/CEOI12_race': 'ceoi-12-SailingRace',
  'https://cses.fi/problemset/task/1722': 'cses-1722',
  'https://cses.fi/problemset/task/1723': 'cses-1723',
  'https://cses.fi/problemset/task/1724': 'cses-1724',
  'https://cses.fi/112/list/': 'baltic-07-ConnectedPoints',
  'https://www.acmicpc.net/problem/7081': 'balkan-09-reading',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=746': 'usaco-746',
  'https://codeforces.com/contest/1017/problem/D': 'cf-1017D',
  'https://codeforces.com/contest/1338/problem/A': 'cf-1338A',
  'https://codeforces.com/contest/1368/problem/D': 'cf-1368D',
  'https://codeforces.com/contest/1088/problem/D': 'cf-1088D',
  'https://www.spoj.com/problems/HORRIBLE': 'spoj-HorribleQueries',
  'https://cses.fi/problemset/task/1736': 'cses-1736',
  'https://oj.uz/problem/view/BOI11_grow': 'baltic-11-GrowingTrees',
  'http://oj.uz/problem/view/IOI07_sails': 'ioi-07-sails',
  'https://cses.fi/problemset/task/1735': 'cses-1735',
  'https://judge.yosupo.jp/problem/range_affine_range_sum':
    'ys-RangeAffineRangeSum',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=578': 'usaco-578',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=418': 'usaco-418',
  'https://cses.fi/problemset/task/2166': 'cses-2166',
  'https://oj.uz/problem/view/IOI14_wall': 'ioi-14-wall',
  'https://dmoj.ca/problem/ioi05p3': 'ioi-05-mountain',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=973': 'usaco-973',
  'https://oj.uz/problem/view/JOI18_bubblesort2': 'joi-18-BubbleSort2',
  'https://dmoj.ca/problem/dmopc20c1p6': 'dmoj-VictorIdentifiesSoftware',
  'https://cses.fi/problemset/task/1139': 'cses-1139',
  'https://codeforces.com/contest/600/problem/E': 'cf-600E',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=696': 'usaco-696',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=842': 'usaco-842',
  'https://szkopul.edu.pl/problemset/problem/sUe3qzxBtasek-RAWmZaxY_p/site/?key=statement':
    'poi-11-TreeRotations',
  'https://oj.uz/problem/view/IOI11_race': 'ioi-11-race',
  'https://oj.uz/problem/view/JOI20_joitter2': 'joi-20-joitter',
  'https://dmoj.ca/problem/coi09p3': 'coi-09-loza',
  'https://oj.uz/problem/view/JOI19_virus': 'joi-19-virus',
  'https://cses.fi/problemset/task/1740': 'cses-1740',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=995': 'usaco-995',
  'https://www.hackerearth.com/practice/data-structures/advanced-data-structures/fenwick-binary-indexed-trees/practice-problems/algorithm/mancunian-and-twin-permutations-d988930c/':
    'he-TwinPermutations',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=816': 'usaco-816',
  'https://cses.fi/problemset/task/1742': 'cses-1742',
  'https://oj.uz/problem/view/IZhO19_sortbooks':
    'izho-19-HedgehogDaniyarAndAlgorithms',
  'https://oj.uz/problem/view/balkan11_trapezoid': 'balkan-11-trapezoid',
  'https://oj.uz/problem/view/COCI16_zoltan': 'coci-16-zoltan',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=865': 'usaco-865',
  'https://oj.uz/problem/view/IZhO12_apple': 'izho-12-MonkeyAndAppletrees',
  'https://oj.uz/problem/view/Balkan15_HAPPINESS': 'balkan-15-happiness',
  'https://judge.yosupo.jp/problem/two_edge_connected_components':
    'ys-TwoEdgeConnectedComponents',
  'https://oj.uz/problem/view/CEOI17_oneway': 'ceoi-17-OneWayStreets',
  'https://cses.fi/problemset/task/1705': 'cses-1705',
  'https://cses.fi/problemset/task/2177': 'cses-2177',
  'https://szkopul.edu.pl/problemset/problem/eDt8w290owtatmCjad0O0ywk/site/?key=statement':
    'poi-08-blockade',
  'https://oj.uz/problem/view/APIO18_duathlon': 'apio-18-duathlon',
  'https://szkopul.edu.pl/problemset/problem/YY6-3ua-C1rt7q-97laWc0UP/site/':
    'poi-16-AmusingJourneys',
  'https://dmoj.ca/problem/tle17c1p6': 'dmoj-investment',
  'https://oj.uz/problem/view/CEOI15_pipes': 'ceoi-15-pipes',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=769': 'usaco-769',
  'https://open.kattis.com/problems/closestpair2': 'kattis-closestpair2',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=943': 'usaco-943',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=266': 'usaco-266',
  'https://oj.uz/problem/view/COCI17_plahte': 'coi-17-plahte',
  'https://oj.uz/problem/view/CEOI06_walk': 'ceoi-06-walk',
  'https://codeforces.com/contest/1402/problem/B': 'ceoi-20-roads',
  'http://oj.uz/problem/view/BOI14_demarcation': 'baltic-14-demarcation',
  'https://open.kattis.com/problems/gridmst': 'kattis-gridmst',
  'https://csacademy.com/contest/archive/task/the-sprawl': 'csa-TheSprawl',
  'https://dmoj.ca/problem/ioi03p6': 'ioi-03-SeeingTheBoundary',
  'https://cses.fi/185/list/': 'ceoi-06-antenna',
  'https://szkopul.edu.pl/problemset/problem/NZSCUwz2ACePsBKuVCIVzrRt/site/':
    'poi-18-stone',
  'https://dmoj.ca/problem/apio10p3': 'apio-10-signaling',
  'https://oj.uz/problem/view/JOI17_dragon2': 'joi-17-Dragon2',
  'https://codeforces.com/contest/815/problem/C': 'cf-815C',
  'https://oj.uz/problem/view/COCI19_dzumbus': 'coci-19-dzumbus',
  'https://codeforces.com/contest/1280/problem/D': 'cf-1280D',
  'http://dmoj.ca/problem/ioi05p6': 'ioi-05-rivers',
  'https://codeforces.com/contest/735/problem/E': 'cf-735E',
  'https://dmoj.ca/problem/coci08c4p6': 'dmoj-COCIPeriodni',
  'https://cses.fi/problemset/task/1741': 'cses-1741',
  'https://codeforces.com/gym/102621/problem/L': 'mbit-ZookeepersGathering',
  'https://oj.uz/problem/view/IOI08_pyramid_base': 'ioi-08-PyramidBase',
  'https://www.hackerrank.com/contests/openbracket-2017/challenges/special-path-on-a-strange-tree/problem':
    'hr-StrangeTree',
  'https://codeforces.com/contest/997/problem/E': 'cf-997E',
  'https://oj.uz/problem/view/CEOI16_kangaroo': 'ceoi-16-kangaroo',
  'https://codeforces.com/group/R2SERIff4f/contest/213171/problem/R': 'cf-2R',
  'https://oj.uz/problem/view/JOI16_skyscraper': 'joi-16-skyscraper',
  'https://cses.fi/problemset/task/2181': 'cses-2181',
  'https://codeforces.com/problemset/problem/845/F': 'cf-845F',
  'https://evaluator.hsin.hr/tasks/HONI202136selotejp/': 'coci-20-selotejp',
  'http://oj.uz/problem/view/CEOI06_connect': 'ceoi-06-connect',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=949': 'usaco-949',
  'https://cses.fi/problemset/task/1654': 'cses-1654',
  'https://codeforces.com/contest/1208/problem/F': 'cf-1208F',
  'https://infoarena.ro/problema/xortransform': 'infoarena-xortransform',
  'https://oj.uz/problem/view/JOI18_snake_escaping': 'joi-18-SnakeEscaping',
  'https://codeforces.com/contest/1326/problem/F2': 'cf-1326F2',
  'https://judge.yosupo.jp/problem/partition_function': 'ys-PartitionFunction',
  'https://judge.yosupo.jp/problem/bernoulli_number': 'ys-BernoulliNumber',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=974': 'usaco-974',
  'https://cses.fi/problemset/task/1691': 'cses-1691',
  'https://cses.fi/problemset/task/1693': 'cses-1693',
  'https://oj.uz/problem/view/BOI14_postmen': 'baltic-14-postmen',
  'https://csacademy.com/contest/archive/task/matching-substrings':
    'csa-MatchingSubstrings',
  'https://codeforces.com/contest/1361/problem/C': 'cf-1361C',
  'https://codeforces.com/contest/528/problem/C': 'cf-528C',
  'https://www.acmicpc.net/problem/13973': 'balkan-16-acrobat',
  'https://open.kattis.com/problems/modulararithmetic':
    'kattis-modulararithmetic',
  'https://open.kattis.com/problems/chineseremainder':
    'kattis-chineseremainder',
  'https://open.kattis.com/problems/generalchineseremainder':
    'kattis-generalchineseremainder',
  'https://dmoj.ca/problem/wac4p6': 'dmoj-HungrySquirrels',
  'https://codeforces.com/group/ZFgXbZSjvp/contest/274398/problem/C':
    'cf-274398C',
  'https://codeforces.com/contest/704/problem/D': 'cf-704D',
  'https://codeforces.com/contest/708/problem/D': 'cf-708D',
  'https://atcoder.jp/contests/jag2013summer-day4/tasks/icpc2013summer_day4_i':
    'ac-MultiPathStory',
  'https://codeforces.com/contest/1416/problem/F': 'cf-1416F',
  'https://oj.uz/problem/view/info1cup17_eastereggs': 'InfO1Cup-17-EasterEggs',
  'https://oj.uz/problem/view/IOI13_cave': 'ioi-13-cave',
  'https://oj.uz/problem/view/IOI18_combo': 'ioi-18-combo',
  'https://oj.uz/problem/view/IOI17_prize': 'ioi-17-TheBigPrize',
  'https://oj.uz/problem/view/IOI16_messy': 'ioi-16-MessyBug',
  'https://oj.uz/problem/view/APIO16_gap': 'apio-16-gap',
  'https://oj.uz/problem/view/CEOI16_icc': 'ceoi-16-icc',
  'https://oj.uz/problem/view/IOI14_rail': 'ioi-14-rail',
  'https://oj.uz/problem/view/IOI15_scales': 'ioi-15-scales',
  'https://oj.uz/problem/view/IOI15_towns': 'ioi-15-towns',
  'https://oj.uz/problem/view/IOI18_highway': 'ioi-18-highway',
  'https://oj.uz/problem/view/IOI17_simurgh': 'ioi-17-simurgh',
  'https://oj.uz/problem/view/APIO17_koala': 'apio-17-KoalaGame',
  'https://oj.uz/problem/view/balkan11_cmp': 'balkan-11-cmp',
  'https://oj.uz/problem/view/IOI11_parrots': 'ioi-11-parrots',
  'https://oj.uz/problem/view/IOI17_coins': 'ioi-17-coins',
  'https://oj.uz/problem/view/IOI19_transfer': 'ioi-19-transfer',
  'https://oj.uz/problem/view/CEOI14_question_grader': 'ceoi-14-question',
  'https://dunjudge.me/analysis/problems/762/': 'joi-15-navigation',
  'https://oj.uz/problem/view/IOI10_saveit': 'ioi-10-saveit',
  'https://oj.uz/problem/view/IOI12_supper': 'ioi-12-LastSupper',
  'https://oj.uz/problem/view/JOI18_airline': 'joi-18-airline',
  'https://oj.uz/problem/view/JOI20_stray': 'joi-20-StrayCat',
  'https://oj.uz/problem/view/JOI19_transportations':
    'joi-19-TwoTransportations',
  'https://dunjudge.me/analysis/problems/1042/': 'joi-14-KanjiShiritori',
  'https://cses.fi/248/list/': 'baltic-19-olympiads',
  'https://dmoj.ca/problem/cco20p6': 'cco-ShoppingPlans',
  'https://judge.yosupo.jp/problem/k_shortest_walk': 'ys-KShortestWalk',
  'https://contest.yandex.com/ioi/contest/566/problems/E/':
    'ioi-05-RectangleGame',
  'https://ioinformatics.org/files/ioi2001problem2.pdf': 'ioi-01-IoiwariGame',
  'https://oj.uz/problem/view/BOI14_coprobber': 'baltic-14-CopAndRobber',
  'https://szkopul.edu.pl/problemset/problem/5Z9PRRPP-R90WhmbSY_qHd-1/site/':
    'poi-04-cave',
  'https://szkopul.edu.pl/problemset/problem/M5CruI5eCu8elnNFHuiXBrvV/site/':
    'poi-16-NotNim',
  'https://szkopul.edu.pl/problemset/problem/y9HM1ctDU8V8xLMRUYACDIRs/site/':
    'poi-16-hydrocontest',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=697': 'usaco-697',
  'https://oj.uz/problem/view/NOI19_feast': 'noisg-19-feast',
  'https://codeforces.com/contest/1279/problem/F': 'cf-1279F',
  'https://open.kattis.com/problems/blazingnewtrails':
    'kattis-blazingnewtrails',
  'http://81.4.170.42:8980/training/#/task/Tennis/statement':
    'balkan-19-tennis',
  'https://oj.uz/problem/view/IOI16_aliens': 'ioi-16-aliens',
  'https://judge.yosupo.jp/problem/line_add_get_min': 'ys-LineAddGetMin',
  'https://oj.uz/problem/view/CEOI17_building': 'ceoi-17-BuildingBridges',
  'https://maps19.kattis.com/problems/marshlandrescues':
    'kattis-MarshlandRescues',
  'https://oj.uz/problem/view/balkan11_2circles': 'balkan-11-2circles',
  'https://judge.yosupo.jp/problem/segment_add_get_min': 'ys-SegmentAddGetMin',
  'https://szkopul.edu.pl/problemset/problem/e9ycK_efBDBt4aPs-QeqYpwR/site/':
    'poi-14-supercomputer',
  'https://www.facebook.com/codingcompetitions/hacker-cup/2020/round-2/problems/D':
    'fhc-LogDrivinHirin',
  'https://atcoder.jp/contests/arc066/tasks/arc066_d':
    'ac-ContestWithDrinksHard',
  'https://tlx.toki.id/contests/troc-13-div-1/problems/D':
    'tlx-MallTransportation',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=534': 'usaco-534',
  'https://www.spoj.com/problems/DYNACON1': 'spoj-DynamicConnectivity',
  'https://codeforces.com/contest/1109/problem/F': 'cf-1109F',
  'https://www.spoj.com/problems/DYNALCA': 'spoj-DynamicLCA',
  'https://judge.yosupo.jp/problem/dynamic_tree_vertex_add_path_sum':
    'ys-VertexAddPathSum',
  'https://judge.yosupo.jp/problem/dynamic_tree_vertex_set_path_composite':
    'ys-VertexAddPathComposite',
  'https://dmoj.ca/problem/wac4p7': 'dmoj-SquirrelCities',
  'https://www.hackerrank.com/contests/pwshpc-online-round/challenges/pwsh-tokens/problem':
    'hr-BalancedTokens',
  'https://cses.fi/174/list/': 'ceoi-11-TreasureHunt',
  'https://codeforces.com/contest/1386/problem/C': 'baltic-20-joker',
  'https://dmoj.ca/problem/ds5easy': 'dmoj-DynamicTreeTestEasy',
  'https://codeforces.com/contest/1344/problem/E': 'cf-1344E',
  'https://codeforces.com/contest/117/problem/E': 'cf-117E',
  'https://codeforces.com/gym/101620': 'cfgym-101620101620',
  'https://oj.uz/problem/view/IOI11_elephants': 'ioi-11-elephants',
  'https://judge.yosupo.jp/problem/dynamic_tree_vertex_add_subtree_sum':
    'ys-VertexAddSubtreeSum',
  'https://codeforces.com/contest/603/problem/E': 'cf-603E',
  'https://judge.yosupo.jp/problem/dynamic_tree_subtree_add_subtree_sum':
    'ys-SubtreeAddSubtreeSum',
  'https://codeforces.com/contest/1172/problem/E': 'cf-1172E',
  'https://dmoj.ca/problem/ds5': 'dmoj-DynamicTreeTest',
  'https://cses.fi/problemset/task/1694': 'cses-1694',
  'https://cses.fi/problemset/task/1711': 'cses-1711',
  'https://cses.fi/problemset/task/1696': 'cses-1696',
  'https://www.spoj.com/problems/FASTFLOW': 'spoj-FastFlow',
  'https://judge.yosupo.jp/problem/bipartitematching': 'ys-BipartiteMatching',
  'https://cses.fi/problemset/task/1709': 'cses-1709',
  'https://open.kattis.com/problems/thewrathofkahn': 'kattis-TheWrathOfKhan',
  'https://cses.fi/problemset/task/1695': 'cses-1695',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=93': 'usaco-93',
  'https://csacademy.com/contest/archive/task/fashion': 'csa-fashion',
  'https://codeforces.com/problemset/problem/808/F': 'cf-808F',
  'https://codeforces.com/contest/1404/problem/E': 'cf-1404E',
  'https://codeforces.com/problemset/problem/724/E': 'cf-724E',
  'http://arc085.contest.atcoder.jp/tasks/arc085_c': 'ac-ARCEMUL',
  'https://www.facebook.com/codingcompetitions/hacker-cup/2015/round-3/problems/C':
    'fhc-gentrification',
  'https://codeforces.com/contest/237/problem/E': 'cf-237E',
  'https://codeforces.com/contest/818/problem/G': 'cf-818G',
  'https://codeforces.com/contest/802/problem/N': 'cf-802N',
  'https://codeforces.com/contest/1307/problem/G': 'cf-1307G',
  'https://codeforces.com/contest/1427/problem/G': 'cf-1427G',
  'https://codeforces.com/contest/1107/problem/F': 'cf-1107F',
  'https://judge.yosupo.jp/problem/persistent_unionfind':
    'ys-PersistentUnionFind',
  'https://judge.yosupo.jp/problem/dynamic_graph_vertex_add_component_sum':
    'ys-VertexAddComponentSum',
  'https://codeforces.com/contest/1140/problem/F': 'cf-1140F',
  'https://codeforces.com/contest/1217/problem/F': 'cf-1217F',
  'https://cses.fi/problemset/task/1737': 'cses-1737',
  'https://evaluator.hsin.hr/tasks/HONI202137specijacija/':
    'coci-20-specijacija',
  'https://dmoj.ca/problem/wac4p5': 'dmoj-TimeTravellingSquirrels',
  'https://cses.fi/problemset/task/1683': 'cses-1683',
  'https://cses.fi/problemset/task/1686': 'cses-1686',
  'https://szkopul.edu.pl/problemset/problem/aKKSmtjWTtDOEHDqnmQ3-eAA/site/?key=statement':
    'poi-18-BikePaths',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=516': 'usaco-516',
  'https://codeforces.com/contest/1239/problem/D': 'cf-1239D',
  'https://szkopul.edu.pl/problemset/problem/p9uJo01RR9ouMLLAYroFuQ-7/site/?key=statement':
    'poi-12-festival',
  'https://open.kattis.com/problems/equivalences': 'kattis-equivalences',
  'https://cses.fi/problemset/task/1685': 'cses-1685',
  'https://cses.fi/problemset/task/1684': 'cses-1684',
  'https://cses.fi/problemset/task/2132': 'cses-2132',
  'https://codeforces.com/contest/866/problem/D': 'cf-866D',
  'https://oj.uz/problem/view/LMIO19_bulves': 'lmio-19-PotatoesFertilizers',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=650': 'usaco-650',
  'https://codeforces.com/gym/102576/problem/C': 'cfgym-102576C',
  'https://www.codechef.com/problems/CCDSAP': 'cc-CCDSAPExam',
  'https://oj.uz/problem/view/CEOI19_magictree': 'ceoi-19-MagicTree',
  'https://oj.uz/problem/view/NOI18_safety': 'noisg-18-safety',
  'https://codeforces.com/gym/102538/problem/F': 'cfgym-102538F',
  'https://codeforces.com/contest/1209/problem/H': 'cf-1209H',
  'https://oj.uz/problem/view/APIO16_fireworks': 'apio-16-fireworks',
  'https://codeforces.com/contest/802/problem/O': 'cf-802O',
  'https://icpc.kattis.com/problems/conquertheworld': 'ICPCWF-ConquerTheWorld',
  'https://judge.yosupo.jp/problem/range_chmin_chmax_add_range_sum':
    'ys-RangeChminChmaxAddRangeSum',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=768': 'usaco-768',
  'https://codeforces.com/edu/course/2/lesson/2/2/practice/contest/269103/problem/A':
    'cf-2A',
  'https://open.kattis.com/problems/stringmultimatching':
    'kattis-stringmultimatching',
  'https://onlinejudge.org/index.php?option=com_onlinejudge&Itemid=8&category=18&page=show_problem&problem=1620':
    'onlinejudgeorg-ILoveStrings',
  'https://www.spoj.com/problems/SUBLEX': 'spoj-LexicographicalStringSearch',
  'https://codeforces.com/contest/547/problem/E': 'cf-547E',
  'https://www.hackerearth.com/practice/data-structures/advanced-data-structures/suffix-trees/practice-problems/algorithm/power-of-string-3/description/':
    'he-PowerOfString3',
  'https://codeforces.com/contest/235/problem/C': 'cf-235C',
  'https://www.acmicpc.net/problem/11555': 'balkan-15-clarkson',
  'https://codeforces.com/contest/653/problem/F': 'cf-653F',
  'https://codeforces.com/contest/1073/problem/G': 'cf-1073G',
  'https://codeforces.com/contest/1207/problem/G': 'cf-1207G',
  'https://codeforces.com/contest/1063/problem/F': 'cf-1063F',
  'https://codeforces.com/contest/1037/problem/H': 'cf-1037H',
  'https://codeforces.com/gym/102129/problem/I': 'cfgym-102129I',
  'https://codeforces.com/contest/932/problem/G': 'cf-932G',
  'https://codeforces.com/contest/1081/problem/H': 'cf-1081H',
  'https://open.kattis.com/problems/stringmatching': 'kattis-stringmatching',
  'https://szkopul.edu.pl/problemset/problem/k9UKIj11V6iPRc3LaiYQYHyi/site/?key=statement':
    'poi-06-PeriodsOfWords',
  'https://oj.uz/problem/view/BOI19_necklace4': 'baltic-19-necklace',
  'http://poj.org/problem?id=3167': 'poj-USACOGold05CowPatterns',
  'https://szkopul.edu.pl/problemset/problem/PT4yHRX9Mmz85ndhNPGCi_WB/site/':
    'poi-05-template',
  'https://szkopul.edu.pl/problemset/problem/6b-q_dPI_KRHD3VArapVq7EP/site/':
    'ceoi-11-matching',
  'https://szkopul.edu.pl/problemset/problem/DkPj-ES6glaEwxppbuaxbOO6/site/':
    'poi-12-prefixuffix',
  'https://szkopul.edu.pl/problemset/problem/H6hUSie6S-cBVL4PG5rqQnmj/site/':
    'poi-11-periodicity',
  'https://judge.yosupo.jp/problem/zalgorithm': 'ys-ZAlgorithm',
  'https://codeforces.com/contest/1051/problem/E': 'cf-1051E',
  'https://codeforces.com/contest/1313/problem/E': 'cf-1313E',
  'https://cses.fi/problemset/task/1111': 'cses-1111',
  'https://codeforces.com/contest/1080/problem/E': 'cf-1080E',
  'https://codeforces.com/contest/1326/problem/D2': 'cf-1326D2',
  'https://codeforces.com/contest/17/problem/E': 'cf-17E',
  'https://oj.uz/problem/view/COCI20_vlak': 'coci-21-vlak',
  'https://oj.uz/problem/view/IOI08_printer': 'ioi-08-TypePrinter',
  'https://judge.yosupo.jp/problem/set_xor_min': 'ys-SetXORMin',
  'https://codeforces.com/contest/1083/problem/B': 'cf-1083B',
  'https://oj.uz/problem/view/COCI20_klasika': 'coci-20-klasika',
  'https://oj.uz/problem/view/IZhO12_xor': 'izho-12-xor',
  'https://oj.uz/problem/view/JOI16_selling_rna': 'joi-16-SellingRNAStrands',
  'https://codeforces.com/contest/1055/problem/F': 'cf-1055F',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=533': 'usaco-533',
  'https://codeforces.com/contest/1202/problem/E': 'cf-1202E',
  'https://oj.uz/problem/view/APIO14_palindrome': 'apio-14-palindrome',
  'https://dmoj.ca/problem/mmcc15p3': 'dmoj-momoka',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=721': 'usaco-721',
  'https://codeforces.com/contest/1396/problem/D': 'cf-1396D',
  'https://codeforces.com/contest/815/problem/D': 'cf-815D',
  'https://codeforces.com/gym/102059/problem/K': 'cfgym-102059K',
  'https://judge.yosupo.jp/problem/suffixarray': 'ys-SuffixArray',
  'https://judge.yosupo.jp/problem/number_of_substrings': 'ys-OfSubstrings',
  'https://codeforces.com/contest/1090/problem/J': 'cf-1090J',
  'https://cses.fi/problemset/task/1113': 'cses-1113',
  'https://judge.yosupo.jp/problem/runenumerate': 'ys-RunEnumerate',
  'https://szkopul.edu.pl/problemset/problem/b0BM0al2crQBt6zovEtJfOc6/site/?key=statement':
    'poi-11-TreeRotations2',
  'https://judge.yosupo.jp/problem/range_kth_smallest': 'ys-RangeKthSmallest',
  'https://open.kattis.com/problems/easyquery': 'kattis-easyquery',
  'https://dmoj.ca/problem/globexcup19s4': 'dmoj-NinjaclashersWrath2',
  'https://atcoder.jp/contests/agc045/tasks/agc045_a': 'ac-XORBattle',
  'https://codeforces.com/contest/1336/problem/E1': 'cf-1336E1',
  'https://codeforces.com/contest/1299/problem/D': 'cf-1299D',
  'https://codeforces.com/gym/102331/problem/E': 'cfgym-102331E',
  'https://atcoder.jp/contests/cf16-exhibition-final/tasks/cf16_exhibition_final_h':
    'ac-abc',
  'https://community.topcoder.com/stat?c=problem_statement&pm=16109':
    'tc-TaleOfTwoSquares',
  'https://cses.fi/problemset/task/1623': 'cses-1623',
  'https://cses.fi/problemset/task/1622': 'cses-1622',
  'https://cses.fi/problemset/task/1624': 'cses-1624',
  'https://cses.fi/problemset/task/1625': 'cses-1625',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=857': 'usaco-857',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=360': 'usaco-360',
  'https://oj.uz/problem/view/COI15_nafta': 'coi-15-nafta',
  'https://codeforces.com/contest/321/problem/E': 'cf-321E',
  'https://codeforces.com/contest/868/problem/F': 'cf-868F',
  'https://szkopul.edu.pl/problemset/problem/2yK6zUTXvAjhxSDfbjE4Zx7k/site/?key=statement':
    'poi-14-SolarLamps',
  'https://oj.uz/problem/view/IOI14_holiday': 'ioi-14-holiday',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=926': 'usaco-926',
  'https://joi2013ho.contest.atcoder.jp/tasks/joi2013ho5': 'joi-13-bubblesort',
  'https://codeforces.com/problemset/problem/342/E': 'cf-342E',
  'https://cses.fi/problemset/task/2079': 'cses-2079',
  'https://codeforces.com/contest/321/problem/C': 'cf-321C',
  'https://codeforces.com/contest/1387/problem/B2': 'baltic-20-VillageMaximum',
  'https://cses.fi/problemset/task/2080': 'cses-2080',
  'https://cses.fi/problemset/task/2081': 'cses-2081',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=817': 'usaco-817',
  'https://codeforces.com/contest/776/problem/F': 'cf-776F',
  'https://codeforces.com/contest/715/problem/C': 'cf-715C',
  'https://codeforces.com/contest/1140/problem/G': 'cf-1140G',
  'https://oj.uz/problem/view/JOI14_factories': 'joi-14-factories',
  'https://dmoj.ca/problem/dmopc19c7p6': 'dmoj-BobEquilibrium',
  'https://dmoj.ca/problem/tc19summerh': 'dmoj-TimeTravellerImaxblue',
  'https://oj.uz/problem/view/JOI13_synchronization': 'joi-13-synchronization',
  'https://codeforces.com/contest/576/problem/C': 'cf-576C',
  'https://oj.uz/problem/view/JOI18_bitaro': 'joi-18-BitarosBirthday',
  'https://szkopul.edu.pl/problemset/problem/oNnWY6ZuzzhvG-jCmijiXkIk/site/?key=statement':
    'poi-17-containers',
  'https://judge.yosupo.jp/problem/static_range_inversions_query':
    'ys-StaticRangeInversionsQuery',
  'https://oj.uz/problem/view/APIO19_bridges': 'apio-19-bridges',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=841': 'usaco-841',
  'https://dmoj.ca/problem/dmopc19c7p7': 'dmoj-FluidDynamics',
  'https://dmoj.ca/problem/wac1p7': 'dmoj-ArithmeticSubtrees',
  'https://oj.uz/problem/view/JOI19_examination': 'joi-19-examination',
  'https://oj.uz/problem/view/IOI09_regions': 'ioi-09-regions',
  'https://codeforces.com/contest/1254/problem/D': 'cf-1254D',
  'https://tlx.toki.id/problems/troc-14/H/': 'tlx-TreeGame',
  'https://csacademy.com/contest/archive/task/shopping-time':
    'csa-ShoppingTime',
  'https://codeforces.com/contest/1178/problem/G': 'cf-1178G',
  'https://oj.uz/problem/view/IOI15_teams': 'ioi-15-teams',
  'https://cses.fi/problemset/task/1619': 'cses-1619',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=786': 'usaco-786',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=787': 'usaco-787',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=896': 'usaco-896',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1064': 'usaco-1064',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=645': 'usaco-645',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1015': 'usaco-1015',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=834': 'usaco-834',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=967': 'usaco-967',
  'https://dmoj.ca/problem/apio17p1': 'dmoj-LandOfTheRainbowGold',
  'https://utipc20s.kattis.com/problems/utipc20s.islandarchipelago':
    'kattis-IslandArchipelago',
  'https://codeforces.com/contest/1392/problem/I': 'cf-1392I',
  'https://codeforces.com/gym/100453': 'cfgym-100453100453',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1094': 'usaco-1094',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=692': 'usaco-692',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=770': 'usaco-770',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=792': 'usaco-792',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=794': 'usaco-794',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=812': 'usaco-812',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=900': 'usaco-900',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=996': 'usaco-996',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1022': 'usaco-1022',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1046': 'usaco-1046',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1044': 'usaco-1044',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1069': 'usaco-1069',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1068': 'usaco-1068',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1092': 'usaco-1092',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1093': 'usaco-1093',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1087': 'usaco-1087',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1108': 'usaco-1108',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1109': 'usaco-1109',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1111': 'usaco-1111',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1112': 'usaco-1112',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1113': 'usaco-1113',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1116': 'usaco-1116',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1117': 'usaco-1117',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1118': 'usaco-1118',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1086': 'usaco-1086',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=1014': 'usaco-1014',
  'https://cses.fi/problemset/task/2220': 'cses-2220',
  'https://cses.fi/problemset/task/1687': 'cses-1687',
  'https://cses.fi/problemset/task/1688': 'cses-1688',
  'https://cses.fi/problemset/task/1135': 'cses-1135',
  'https://cses.fi/problemset/task/1750': 'cses-1750',
  'https://cses.fi/problemset/task/1160': 'cses-1160',
  'https://szkopul.edu.pl/problemset/problem/qDH9CkBHZKHY4vbKRBlXPrA7/site/?key=statement':
    'poi-10-frog',
  'https://codeforces.com/contest/1142/problem/B': 'cf-1142B',
  'https://oj.uz/problem/view/BOI19_valley': 'baltic-19-valley',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=648': 'usaco-648',
  'https://oj.uz/problem/view/BOI15_edi': 'baltic-15-editor',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=576': 'usaco-576',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=948': 'usaco-948',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=866': 'usaco-866',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=901': 'usaco-901',
  'https://codeforces.com/gym/102694/problem/C': 'cfgym-102694C',
  'https://codeforces.com/contest/588/problem/E': 'cf-588E',
  'https://oj.uz/problem/view/BOI17_railway': 'baltic-17-railway',
  'https://codeforces.com/contest/609/problem/E': 'cf-609E',
  'https://csacademy.com/contest/archive/task/root-lca-queries':
    'csa-RootLCAQueries',
  'https://codeforces.com/contest/1000/problem/G': 'cf-1000G',
  'https://dmoj.ca/problem/bts17p7': 'dmoj-HotCold',
  'https://tlx.toki.id/contests/troc-12-div-1/problems/D':
    'tlx-FunctionalConstraint',
  'https://tlx.toki.id/contests/troc-13-div-1/problems/E':
    'tlx-GraphDestination',
  'https://www.hackerrank.com/challenges/subtrees-and-paths':
    'hr-SubtreesPaths',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=970': 'usaco-970',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=921': 'usaco-921',
  'https://cses.fi/problemset/task/2134': 'cses-2134',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=102': 'usaco-102',
  'https://judge.yosupo.jp/problem/vertex_set_path_composite':
    'ys-VertexSetPathComposite',
  'https://oj.uz/problem/view/JOI18_catdog': 'joi-18-CatsOrDogs',
  'https://open.kattis.com/problems/shortestpath3': 'kattis-shortestpath3',
  'https://cses.fi/problemset/task/1197': 'cses-1197',
  'https://oj.uz/problem/view/RMI19_restore': 'rmi-RestoreArray',
  'https://open.kattis.com/problems/allpairspath': 'kattis-allpairspath',
  'https://oj.uz/problem/view/APIO17_merchant': 'apio-17-TravelingMerchant',
  'https://tlx.toki.id/problems/apio-2013/C/': 'apio-13-tasksauthor',
  'https://cses.fi/problemset/task/1143': 'cses-1143',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=231': 'usaco-231',
  'https://judge.yosupo.jp/problem/point_set_range_composite':
    'ys-PointSetRangeComposite',
  'https://cses.fi/problemset/task/1190': 'cses-1190',
  'https://cses.fi/problemset/task/2206': 'cses-2206',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=495': 'usaco-495',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=365': 'usaco-365',
  'https://szkopul.edu.pl/problemset/problem/qpsk3ygf8MU7D_1Es0oc_xd8/site/?key=statement':
    'poi-14-cards',
  'http://www.usaco.org/index.php?page=viewproblem2&cpid=577': 'usaco-577',
  'https://oj.uz/problem/view/BOI18_election': 'balkan-18-election',
  'https://judge.yosupo.jp/problem/convolution_mod': 'ys-ConvolutionMod',
  'https://judge.yosupo.jp/problem/convolution_mod_1000000007':
    'ys-ConvolutionMod1097',
  'https://szkopul.edu.pl/problemset/problem/9JvSAnyf5d1FlPAEXEdUAtCz/site/':
    'poi-18-polynomial',
  'https://open.kattis.com/problems/kinversions': 'kattis-kinversions',
  'https://open.kattis.com/problems/matchings': 'kattis-matchings',
  'https://open.kattis.com/problems/aliencodebreaking':
    'kattis-aliencodebreaking',
  'https://judge.yosupo.jp/problem/frequency_table_of_tree_distance':
    'ys-FrequencyTableOfTreeDistance',
  'https://dmoj.ca/problem/bts17p8': 'dmoj-BigInteger',
  'https://cses.fi/problemset/task/1137': 'cses-1137',
  'https://judge.yosupo.jp/problem/staticrmq': 'ys-StaticRMQ',
  'https://cses.fi/problemset/task/1138': 'cses-1138',
  'https://dmoj.ca/problem/dmopc20c1p5': 'dmoj-VictorTakesOverCanada',
};
